import React from 'react'
import Button from '@material-ui/core/Button'
import { TextField, CircularProgress } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { Link as MuiLink } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { Formik } from 'formik'
import { useHistory } from "react-router-dom"
import Notification from '../../../components/notification'
import validationSchema from '../validation-rfc.js'
import PropTypes from 'prop-types'
import Logo from '../../../static/logo/logo-fidepol-512.png'

const SignInRfc = (props) => {
    const { classes, login, onChangeView, UserInfo } = props;
    const navigate = useHistory();
    const [externalError, setExternalError] = React.useState(null);

    const onForgethandler = (e) => {
        e.preventDefault()
        onChangeView({
            signin: false,
            forgetpassword: true
        })
    }

    return (
        <div className={classes.paper}>
            <img src={Logo} alt="Logo" style={{ width: 250 }} loading="lazy" />
            {/* <Img src={require('../../../static/logo/logo-fidepol.png')} sizes={[250]} /> */}
            <Formik
                initialValues={{ rfc: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setExternalError(null)
                    const response = await login({
                        username: values.rfc,
                        password: values.password
                    })
                    setSubmitting(false)
                    if (response) {
                        const _user = UserInfo()
                        if (_user.roles.includes("adm"))
                            navigate.push("/admin/query-accountstatus")
                        else
                            navigate.push("/admin/pending-application")
                    }
                    else
                        setExternalError("Usuario y/o contraseña incorrectos")
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="rfc"
                            label="Rfc"
                            name="rfc"
                            autoComplete="rfc"
                            autoFocus
                            onChange={handleChange}
                            value={values.rfc}
                            error={touched.rfc && errors.rfc}
                            helperText={errors.rfc}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            error={touched.password && errors.password}
                            helperText={errors.password}
                        />
                        {!isSubmitting &&
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Ingresar
                            </Button>
                        }
                        <Grid container>
                            <Grid item xs={12} className={classes.spinner}>
                                {isSubmitting && <CircularProgress />}
                            </Grid>
                        </Grid>
                        {!isSubmitting &&
                            <Grid container>
                                <Grid item xs>
                                    <Link onClick={onForgethandler} href="#" variant="body2">Olvidaste tu contraseña?</Link>
                                </Grid>
                                <Grid item>
                                    <Link component={MuiLink} to="/sign-up" variant="body2">
                                        {"Registrarse"}
                                    </Link>
                                </Grid>
                            </Grid>
                        }
                        {externalError &&
                            <Grid container>
                                <Grid item xs={12}>
                                    <Notification isOpen={externalError ? true : false} message={externalError} />
                                </Grid>
                            </Grid>
                        }
                    </form>
                )}
            </Formik>
        </div>

    )
}

SignInRfc.propTypes = {
    classes: PropTypes.object,
    login: PropTypes.func,
    onChangeView: PropTypes.func,
    UserInfo: PropTypes.func
}

export default SignInRfc
